import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, values },
  } = theme;
  return {
    main_container_layout_4: {
      width: "100% !important",
      margin: "0px !important",
      backgroundColor: "white",
      padding: spacing(6),
      [below(1025)]: {
        padding: 0,
      },
    },
    main_story_and_ad_container: {
      display: "flex",
      flexWrap: "nowrap",
      gap: spacing(6),
      alignItems: "center",
      "& [class*='ad_card_for_top_stories']": {
        width: "fit-content",
        [below(1025)]: {
          width: "100%",
        },
      },
      [below(1225)]: {
        gap: spacing(3),
      },
      [below(1025)]: {
        flexWrap: "wrap",
        justifyContent: "center",
      },
      [below(values.sm)]: {
        paddingTop: "0px !important",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
      },
    },
    container_mobile_layout_4: {
      height: "fit-content !important",
    },
  };
});

export { useStyles };
