import React from "react";
import { useStyles } from "./TopSection.style";
import { useFooterManagement } from "../../../context/FooterContext";
import SubscribeForm from "../SubscribeForm/SubscribeForm";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import withConfig from "../../../utils/withConfig";
import MasterheadLogo from "../../Masterhead/MasterheadLogo";
import clsx from "clsx";

const LAYOUT_TWO = "layout_two";

const TopSection = ({}) => {
  const { footer, footerLayout, town } = useFooterManagement();
  const classes = useStyles(footer?.display_sign_up_form);

  const TapintoLogo = () => {
    return (
      <MasterheadLogo
        envUrl={withConfig("HOME")}
        townName={town?.name}
        townSlug={town?.slug}
      />
    );
  };

  const shouldDisplay = () => {
    return footerLayout !== undefined && footerLayout === LAYOUT_TWO;
  };

  return (
    <>
      {(screenWidthIsLowerThan(1150) || shouldDisplay()) && (
        <div
          className={clsx(
            classes.container,
            footer?.display_sign_up_form
              ? classes.subscription_container
              : classes.logo_container
          )}
        >
          {footer?.display_sign_up_form && <SubscribeForm />}
          {!footer?.display_sign_up_form && <TapintoLogo />}
        </div>
      )}
    </>
  );
};

TopSection.propTypes = {};

export default TopSection;
