import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./BottomSection.style";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import TapintoLogo from "../../../common/Icons/TapintoLogo";
import Link from "../../../common/Link/Link";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import TapintoLogoSmall from "../../../common/Icons/TapintoLogoSmall";

const BottomSection = ({ withLogo }) => {
  const classes = useStyles();

  const CopyRight = () => {
    return (
      <div className={classes.copy_right}>
        <Typography color="white" level="t4_text_4">
          {labels.COPY_RIGHT.replace("##year", new Date().getFullYear())}
        </Typography>
        <Typography color="white" level="t4_text_4">
          {labels.RIGHTS_RESERVED}
        </Typography>
      </div>
    );
  };

  const SeniropInfo = () => {
    return (
      <div className={classes.senirop_info}>
        <Typography color="white" level="t4_text_4" bold>
          {labels.DEVELOPED_BY}
        </Typography>
        {
          <Link
            color="white"
            level="t4_text_4"
            url="https://www.senirop.com"
            target="_blank"
            className={classes.senirop}
          >
            {labels.SENIROP}
          </Link>
        }
      </div>
    );
  };

  return withLogo ? (
    <div className={classes.container}>
      <CopyRight />
      <Link color="white" level="t4_text_4" url="/" className={classes.logo}>
        {!screenWidthIsLowerThan(900) && <TapintoLogo />}
        {screenWidthIsLowerThan(900) && <TapintoLogoSmall />}
      </Link>
      <SeniropInfo />
    </div>
  ) : (
    <></>
  );
};

BottomSection.propTypes = {
  withLogo: PropTypes.bool,
};

export default BottomSection;
