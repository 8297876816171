import React from "react";
import { string, bool } from "prop-types";
import Typography from "../../../Typography/Typography";
import VideoPlayer from "../../../VideoPlayer/VideoPlayer";
import { useStyles } from "./SlideWithTitle.style";
import { screenWidthIsHigherThan } from "../../../../hooks/useMediaQuery";

const SlideWithTitle = ({ src, title, altText, isVideo }) => {
  const classes = useStyles();
  return (
    <div>
      {isVideo ? (
        <VideoPlayer
          url={src}
          width="90vw"
          {...(screenWidthIsHigherThan(960) && { height: "80vh" })}
        />
      ) : (
        <img src={src} alt={altText} className={classes.image} />
      )}

      {title && (
        <div className={classes.container}>
          <Typography level="t4_text_4" color="white">
            {title}
          </Typography>
        </div>
      )}
    </div>
  );
};
SlideWithTitle.propTypes = {
  src: string,
  title: string,
  altText: string,
  isVideo: bool,
};

export default SlideWithTitle;
