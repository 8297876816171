import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: theme.spacing(29),
      width: "100%",
      backgroundColor: theme.palette.miscellaneous.lightBlack,
      [theme.breakpoints.down(900)]: {
        height: "auto",
        backgroundColor: theme.palette.grayVariation.grayLightFooter,
      },
    },
    label_at_top: {
      maxWidth: theme.spacing(50),
      [theme.breakpoints.down(900)]: {
        maxWidth: "100%",
        width: "100%",
        paddingTop: theme.spacing(3),
      },
    },
    get_local_news: {
      fontSize: "22px !important",
    },
    email_container_centered: {
      textAlign: "center",
      [theme.breakpoints.down(900)]: {
        width: "100%",
        paddingBottom: theme.spacing(3),
      },
    },
    email_and_button: {
      display: "flex",
      [theme.breakpoints.down(1150)]: {
        justifyContent: "center",
      },
    },
    email_and_button_at_top: {
      justifyContent: "center",
    },
    email: {
      "& .MuiInputBase-root": {
        borderRadius: "unset",
      },
    },
    signup: {
      height: theme.spacing(4),
      backgroundColor: `${theme.palette.themeColor.primary} !important`,
      borderRadius: "unset !important",
    },
    red_input_border: {
      marginTop: theme.spacing(2),
      width: "100%",
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
        borderWidth: "2px",
      },
    },
    error_message: {
      color: "red",
    },
    success_message: {
      paddingTop: theme.spacing(1),
      display: "block",
    },
    white_sign_up_footer: {
      " & div": {
        marginTop: 0,
        color: "white",
      },
    },
    already_subscribed_link: {
      "& span": {
        color: `${theme.palette.themeColor.secondary} !important`,
      },
    },
  };
});

export { useStyles };
