import React from "react";
import PropTypes from "prop-types";
import Typography from "../../common/Typography/Typography";
import { useStyles } from "./Sponsor.style";
import labels from "../../config/labels";
import {
  screenWidthIsHigherThan,
  screenWidthIsLowerThan,
} from "../../hooks/useMediaQuery";
import { buildAdTargetsForSponsor } from "../../utils/googleTagManagerHelper";
import Ad from "../Ad/Ad";
import clsx from "clsx";

const SPONSOR_AD_UNIT = "sponsor_ad_unit";

const Sponsor = ({ sponsor_info, displayInMobile, isInRightBlock }) => {
  const classes = useStyles();
  const size = [sponsor_info?.width, sponsor_info?.height];
  const targets = buildAdTargetsForSponsor(sponsor_info);
  const weatherSponsor = sponsor_info?.sponsor_attr === "weather_navbar";
  const sponsorBlock = () => {
    return (
      <div
        className={clsx(
          !isInRightBlock
            ? classes.sponsor_container
            : classes.sponsor_container_sidebar,
          weatherSponsor && classes.container_for_weather
        )}
      >
        {!weatherSponsor && (
          <Typography
            level="medium_9_px"
            color="black"
            className={classes.sponsored_by}
          >
            {labels.SPONSORED_BY}
          </Typography>
        )}

        <Ad
          ad_unit={SPONSOR_AD_UNIT}
          size={size}
          targets={targets}
          element_id={
            "sponsor-" + sponsor_info?.index + sponsor_info?.sponsor_attr
          }
        />
      </div>
    );
  };

  return (
    <>
      {displayInMobile && screenWidthIsLowerThan(1024) && sponsorBlock()}
      {displayInMobile === false &&
        screenWidthIsHigherThan(1024) &&
        sponsorBlock()}
      {displayInMobile === undefined && sponsorBlock()}
    </>
  );
};

Sponsor.propTypes = {
  sponsor_info: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    town: PropTypes.string,
    sections_slug: PropTypes.arrayOf(PropTypes.string),
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    top_local_news: PropTypes.bool,
    page_type: PropTypes.string,
  }),
  displayInMobile: PropTypes.bool,
  isInRightBlock: PropTypes.bool,
};

Sponsor.defaultProps = {
  displayInMobile: undefined,
  isInRightBlock: false,
};

export default Sponsor;
