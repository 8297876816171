import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import restClient from "../utils/restClient";
import withConfig from "../utils/withConfig";

const LAYOUT_ONE = "layout_one";
const LAYOUT_TWO = "layout_two";

const FooterContext = React.createContext({});

const FooterContextProvider = ({ children }) => {
  const [footer, setFooter] = useState();
  const [footerLayout, setFooterLayout] = useState();
  const [town, setTown] = useState();
  const [blockLinks, setBlockLinks] = useState([]);
  const [firstBlock, setFirstBlock] = useState([]);
  const [secondBlock, setSecondBlock] = useState([]);
  const [thirdBlock, setThirdBlock] = useState([]);
  const [fourthBlock, setFourthBlock] = useState([]);

  const setOverallFooter = async () => {
    const response = await restClient.get(withConfig("OVERALL_FOOTER_LINKS"));

    setFooter(response?.data?.footer);
    setBlockLinks(response?.data?.footer_links);
  };

  useEffect(() => {
    const loadFooterData = async () => {
      const response = await restClient.get(
        `${withConfig("FOOTER_BY_TOWN").replace(":id", town?.id)}`
      );

      setFooter(response?.data?.footer);
      setBlockLinks(response?.data?.footer_links);
    };
    if (town) {
      loadFooterData();
    }
  }, [town]);

  useEffect(() => {
    if (blockLinks.length > 0) {
      const blockNumbers = blockLinks
        .map((link) => {
          return link.block_number;
        })
        .sort();
      const uniqueBlocksCount = [...new Set(blockNumbers)].length;

      switch (uniqueBlocksCount) {
        case 1:
        case 2:
        case 3:
          setFooterLayout(LAYOUT_ONE);
          break;
        case 4:
          setFooterLayout(LAYOUT_TWO);
          break;
        default:
          setFooterLayout();
          break;
      }

      setFirstBlock(blockLinks.filter((block) => block.block_number === 1));
      setSecondBlock(blockLinks.filter((block) => block.block_number === 2));
      setThirdBlock(blockLinks.filter((block) => block.block_number === 3));
      setFourthBlock(blockLinks.filter((block) => block.block_number === 4));
    }
  }, [blockLinks]);

  return (
    <FooterContext.Provider
      value={{
        footer,
        setTown,
        town,
        footerLayout,
        firstBlock,
        secondBlock,
        thirdBlock,
        fourthBlock,
        setOverallFooter,
      }}
    >
      {children}
    </FooterContext.Provider>
  );
};

FooterContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useFooterManagement = () => {
  const FooterContextValues = useContext(FooterContext);

  if (!FooterContextValues) {
    throw new Error(
      "useContext must be used within a descendant of the FooterContextProvider"
    );
  }

  return FooterContextValues;
};

const withFooterContextProvider = (Component) => {
  const ComponentWithProvider = (props) => (
    <FooterContextProvider>
      <Component {...props} />
    </FooterContextProvider>
  );

  return ComponentWithProvider;
};

export { useFooterManagement, withFooterContextProvider };
