import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./Footer.style";
import {
  useFooterManagement,
  withFooterContextProvider,
} from "../../context/FooterContext";
import TopSection from "./TopSection/TopSection";
import MiddleSection from "./MiddleSection/MiddleSection";
import BottomSection from "./BottomSection/BottomSection";

const Footer = ({ town, with_footer_logo }) => {
  const classes = useStyles(town);
  const withLogo = town?.with_footer_logo
    ? town?.with_footer_logo
    : with_footer_logo;
  const { setTown, footerLayout, setOverallFooter } = useFooterManagement();

  useEffect(async () => {
    if (town) {
      setTown(town);
    } else {
      await setOverallFooter();
    }
  }, [town]);

  if (footerLayout === undefined) {
    return <>Loading footer...</>;
  }

  return (
    <div className={clsx(classes.container, classes.no_printing)}>
      <TopSection />
      <MiddleSection />
      <BottomSection withLogo={withLogo} />
    </div>
  );
};

Footer.propTypes = {
  town: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
    twitter_url: PropTypes.string,
    facebook_url: PropTypes.string,
    instagram_url: PropTypes.string,
  }),
};

export default withFooterContextProvider(Footer);
