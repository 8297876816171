import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { down: below, values },
  } = theme;

  const defaultStyles = {
    trucated_text: {
      textOverflow: "ellipsis",
      display: "box",
      boxOrient: "vertical",
      overflow: "hidden",
      height: "fit-content",
    },
    header_container: {
      height: "fit-content",
    },
    media_container: {
      backgroundColor: palette.light.imageBackground,
    },
    image: {
      backgroundPosition: "top center",
      backgroundRepeat: "no-repeat",
    },
  };

  return {
    // Layout 1
    header_layout_1: {
      fontSize: "18px !important",
      fontFamily: "PT Serif, serif !important",
      fontWeight: "700 !important",
    },
    header_container_truncated_layout_1: {
      ...defaultStyles.trucated_text,
      lineClamp: 5,
      width: `calc(100% - ${spacing(19)}px)`,
    },
    header_container_layout_1: {
      width: `calc(100% - ${spacing(19)}px)`,
      ...defaultStyles.header_container,
    },
    image_layout_1: {
      height: spacing(17),
      width: spacing(17),
      backgroundSize: "contain",
      ...defaultStyles.image,
      backgroundPosition: "center",
    },
    media_container_layout_1: {
      ...defaultStyles.media_container,
      height: spacing(17),
      width: spacing(17),
    },
    container_layout_1: {
      gap: spacing(2),
    },

    // Layout 2
    container_layout_2: {
      gap: spacing(2),
      "&:hover, &:focus-within": {
        backgroundColor: "#EBEBEB",
      },
    },
    header_layout_2: {
      fontSize: "18px !important",
    },
    header_container_truncated_layout_2: {
      ...defaultStyles.trucated_text,
      lineClamp: 4,
      width: "calc(100% - 168px)",
    },
    header_container_layout_2: {
      width: "calc(100% - 168px)",
      ...defaultStyles.header_container,
    },
    media_container_layout_2: {
      height: "100px",
      width: "150px",
    },
    image_layout_2: {
      height: "100px",
      width: "150px",
      backgroundSize: "cover",
      ...defaultStyles.image,
    },

    // Layout 4
    link_layout_4: {
      width: "calc(100% - 380px)",
      [below(1025)]: {
        width: "100%",
      },
    },
    container_layout_4: {
      gap: spacing(6),
      height: "413px",
      flexWrap: "nowrap !important",
    },
    header_container_truncated_layout_4: {
      ...defaultStyles.trucated_text,
      width: '30%',
      lineClamp: 10,
    },
    header_container_layout_4: {
      ...defaultStyles.header_container,
      width: "30%",
    },
    header_layout_4: {
      fontSize: "32px !important",
      lineHeight: "40px !important",
      fontWeight: "700 !important",
    },
    media_container_layout_4: {
      height: "100%",
      width: "70%",
    },
    image_layout_4: {
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
      ...defaultStyles.image,
    },

    // Layout 5
    container_layout_5: {
      paddingTop: spacing(1),
      paddingBottom: spacing(2),
      borderBottom: "1px solid #8E8E8E",
    },
    header_container_truncated_layout_5: {
      ...defaultStyles.trucated_text,
      display: "-webkit-box !important",
      lineClamp: 2,
      "& h5": {
        fontSize: "18px",
        margin: 0,
      },
      [below(values.sm)]: {
        padding: `0px ${spacing(1)}px`,
      },
    },
    header_container_layout_5: {
      ...defaultStyles.header_container,
      [below(values.sm)]: {
        padding: `0px ${spacing(1)}px`,
      },
    },
    header_layout_5: {
      "& h5": {
        display: "inline",
        fontSize: "18px",
        margin: 0,
        fontWeight: "500",
        transition: "font-weight 0.2s ease",
        "&:hover, &:focus-within": {
          fontWeight: "700",
          transition: "font-weight 0.2s ease",
        },
      },
    },
  };
});

export { useStyles };
