const footerLabels = {
  FOOTER_MANAGER: "Footer Manager",
  PREVIEW_FOOTER: "Preview",
  NEW_FOOTER_LINK: "New Footer Link",
  SAVE_FOOTER: "Save Footer",
  SHOW_IN_FIRST_BLOCK: "What do you want to display in your first block?",
  SHOW_SOCIAL_MEDIA: "Do you want to show your social media icons?",
  REMOVE_FOOTER_LINK_CONFIRMATION: "Are you sure you want to remove this link?",
  SUBSCRIBE_BOX: "Subscription form",
  TAPINTO_LOGO: "TAPinto's logo",
  LABEL: "Label",
  URL: "URL",
  SELECT_A_PAGE: "Select a page",
  BUILD_YOUR_FOOTER_LINKS: "Build your Footer links",
  FIRST_BLOCK: "First block",
  SECOND_BLOCK: "Second block",
  THIRD_BLOCK: "Third block",
  FOURTH_BLOCK: "Fourth block",
  SAVE: "Save",
};

export default footerLabels;
