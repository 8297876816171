import withConfig from "../utils/withConfig";
import restClient from "../utils/restClient";

export const fetchOverallPageLinks = async () => {
  try {
    const response = await restClient.get(
      withConfig("FETCH_OVERALL_MASTHEAD_LINKS"),
      {}
    );
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchEditorInfo = async (payload) => {
  try {
    const response = await restClient.get(
      withConfig("FETCH_EDITOR_INFO"),
      payload
    );
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchSubmitContentItems = async (townId) => {
  try {
    const response = await restClient.get(
      withConfig("FETCH_SUBMIT_CONTENT_ITEMS"),
      { town_id: townId }
    );
    return response;
  } catch (e) {
    throw e;
  }
};
