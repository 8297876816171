import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      marginTop: (town) => (town ? theme.spacing(6) : 0),
      "@media print": {
        display: window.location.href.includes("legal_notices")
          ? "none"
          : "block",
      },
    },
    no_printing: {
      "@media print": {
        display: "none",
      },
    },
  };
});

export { useStyles };
