import { useState, useEffect } from "react";

const getWindowWidth = () => {
  const { innerWidth: width } = window;
  return width;
};

const simpleMediaQuery = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowWidth());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowWidth());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

const screenWidthIsLowerThan = (widthInPixels) => {
  const [lowerThan, setLowerThan] = useState(getWindowWidth() < widthInPixels);

  useEffect(() => {
    function handleResize() {
      setLowerThan(getWindowWidth() < widthInPixels);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return lowerThan;
};

const screenWidthIsHigherThan = (widthInPixels) => {
  const [higherThan, setHigherThan] = useState(
    getWindowWidth() > widthInPixels
  );

  useEffect(() => {
    function handleResize() {
      setHigherThan(getWindowWidth() > widthInPixels);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return higherThan;
};

const deviceDetector = () => {
  const [deviceType, setDeviceType] = useState({
    mobile: false,
    desktop: false,
  });

  useEffect(() => {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true;
      } else {
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    if (hasTouchScreen) {
      setDeviceType({ mobile: true, desktop: false });
    } else {
      setDeviceType({ mobile: false, desktop: true });
    }
  }, []);

  return deviceType;
};

export {
  simpleMediaQuery,
  screenWidthIsLowerThan,
  screenWidthIsHigherThan,
  getWindowWidth,
  deviceDetector,
};
