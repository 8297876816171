import React, { useMemo } from "react";
import Grid from "../../../../../common/Grid/Grid";
import VerticalCard from "../../VerticalCard/VerticalCard";
import HorizontalCard from "../../HorizontalCard/HorizontalCard";
import VideoAd from "../../../../Ad/VideoAd";
import AdCard from "../../../../Homepage/AdCard/AdCard";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import { useStyles } from "./Layout4.style";
import values from "../../../../../common/Theme/themes/breakpoints/values";

const Layout4 = ({ adTargets, videoAd, localNews }) => {
  const classes = useStyles();
  const isSmallScreen = screenWidthIsLowerThan(values.sm);
  const isLargeScreen = screenWidthIsLowerThan(1225);
  const { mainStory, secondaryStories } = localNews || {};
  const widthSecondaryStory = useMemo(() => {
    if (isSmallScreen) {
      return 12;
    }
    if (isLargeScreen) {
      return 6;
    }
    return 3;
  }, [isLargeScreen, isSmallScreen]);

  return (
    <Grid
      container
      spacing={isLargeScreen ? 3 : 5}
      className={classes.main_container_layout_4}
    >
      <Grid
        item
        xs={12}
        classes={{ root: classes.main_story_and_ad_container }}
      >
        {isSmallScreen ? (
          <VerticalCard
            variant="mobile_layout_4"
            contents={mainStory}
            classes={{
              container: { root: classes.container_mobile_layout_4 },
            }}
          />
        ) : (
          <HorizontalCard variant="layout_4" contents={mainStory} options={{
            truncatedHeader: true
          }}/>
        )}

        {videoAd && Object.keys(videoAd).length > 0 ? (
          <VideoAd videoAd={videoAd} />
        ) : (
          <AdCard
            adTargets={adTargets}
            adSize={[300, 250]}
            adUnit="tap_bullseye_001"
            options={{
              forTopStories: true,
            }}
          />
        )}
      </Grid>

      {secondaryStories?.map((story) => (
        <Grid xs={widthSecondaryStory} item key={story?.id}>
          <VerticalCard variant="layout_4" contents={story} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Layout4;
