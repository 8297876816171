import Grid from "../Grid/Grid";
import clsx from "clsx";
import { node, object, oneOf, shape, string } from "prop-types";
import React from "react";
import ReactPlayer from "react-player";
import { useStyles } from "./Jumbotron.style";
import { redirectToPage } from "../../utils/helper";

const Jumbotron = ({
  backgroundImageUrl,
  videoUrl,
  classes,
  options,
  variant,
  contents,
}) => {
  const defaultClasses = useStyles();
  const imageBackgroundStyle = {
    backgroundImage: `url(${backgroundImageUrl})`,
  };

  const contentsClasses = () => {
    switch (options?.contentsType) {
      case "contained":
        return clsx(
          defaultClasses.contents_container_contained,
          classes?.contents
        );
      default:
        return classes?.contents;
    }
  };

  switch (variant) {
    case "image":
      return (
        <Grid
          {...(options?.redirectTo && {
            onClick: () => redirectToPage(options?.redirectTo, "_top"),
          })}
          container
          style={imageBackgroundStyle}
          classes={{
            root: clsx(
              defaultClasses.image_container,
              options?.redirectTo && defaultClasses.clickable,
              classes?.container?.root
            ),
          }}
        >
          {contents && <div className={contentsClasses()}>{contents}</div>}
        </Grid>
      );
    case "video":
      return (
        <Grid
          classes={{
            root: clsx(defaultClasses.container, classes?.container?.root),
          }}
        >
          <ReactPlayer url={videoUrl} width="100%" height="100%" />
          {contents && <div className={contentsClasses()}>{contents}</div>}
        </Grid>
      );
    default:
      return (
        <Grid
          container
          classes={{
            root: clsx(defaultClasses.container, classes?.container?.root),
          }}
        >
          {contents && <div className={contentsClasses()}>{contents}</div>}
        </Grid>
      );
  }
};

Jumbotron.propTypes = {
  contents: node,
  variant: oneOf(["default", "image", "video"]),
  options: shape({
    contentsType: oneOf(["contained"]),
    redirectTo: string,
  }),
  classes: shape({
    container: object,
    contents: string,
  }),
  backgroundImageUrl: string,
  videoUrl: string,
};

Jumbotron.defaultProps = {
  variant: "default",
  options: {
    contentsType: "contained",
  },
};

export default Jumbotron;
