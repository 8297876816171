import React from "react";
import PropTypes from "prop-types";
import SlideWithCustomCaptionAndCredits from "./WithCustomCaptionsAndCredits/WithCustomCaptionAndCredits";
import SlideWithTitle from "./WithTitle/SlideWithTitle";

const CustomSlide = ({ type, slide }) => {
  const ChosenSlideByType = () => {
    switch (type) {
      case "with-custom-credits-and-caption":
        return (
          <SlideWithCustomCaptionAndCredits
            src={slide.src}
            caption={slide.caption}
            credits={slide.credits}
            altText={slide.alt}
            isVideo={slide.isVideo}
          />
        );
      case "with-title":
        return (
          <SlideWithTitle
            src={slide.src}
            title={slide.title}
            altText={slide.alt}
            isVideo={slide.isVideo}
          />
        );
      default:
        break;
    }
  };

  return <ChosenSlideByType />;
};
CustomSlide.propTypes = {
  type: PropTypes.oneOf(["with-custom-credits-and-caption", "with-title"]),
  slide: PropTypes.shape({
    src: PropTypes.string,
    caption: PropTypes.string,
    credits: PropTypes.string,
    alt: PropTypes.string,
    isVideo: PropTypes.bool,
  }),
};

export default CustomSlide;
